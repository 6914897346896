import {
    PersonOutlineOutlined,
    HttpsOutlined,
    VisibilityOff,
    Visibility,
} from "@mui/icons-material";

import {
    Box,
    TextField,
    InputAdornment,
    IconButton,
    Button,
    Typography,
    Modal,
    Fade,
    Backdrop,
} from "@mui/material";
import styles from "./Login.module.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Login = () => {
    const navigate = useNavigate();
    const [inputData, setInputData] = useState({
        username: "",
        password: "",
        showPassword: false,
    });

    //modal
    const [open, setOpen] = useState(false);
    const toggleModal = () => setOpen(!open);

    const [error, setError] = useState({
        isError: false,
        errMessage: "",
    });

    const handleClickShowPassword = () => {
        setInputData({
            ...inputData,
            showPassword: !inputData.showPassword,
        });
    };

    const handleLogin = async (e: any) => {
        e.preventDefault();
        const apiUrl = process.env.REACT_APP_API_URL;
        const { username, password } = inputData;

        const response = await fetch(apiUrl!, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                username,
                password,
            }),
        });

        const data = await response.json();
        if (data.error) {
            setError({
                isError: true,
                errMessage: data.error,
            });
        } else {
            localStorage.setItem("token", data.token);
            navigate("/dashboard");
        }
    };

    return (
        <>
            <div className={styles.formContainer}>
                <Typography
                    variant="body2"
                    sx={{
                        color: "#030037",
                        fontSize: "32px",
                        cursor: "pointer",
                        my: 0.5,
                        fontFamily: "Nunito Sans",
                        fontWeight: "700",
                    }}
                >
                    Welcome
                </Typography>
                <Typography
                    variant="body2"
                    sx={{
                        color: "#686687",
                        fontSize: "14px",
                        cursor: "pointer",
                        fontFamily: "Nunito Sans",
                        fontWeight: "400",
                    }}
                >
                    Enter your Username and Passoword.
                </Typography>

                <form method="POST" onSubmit={handleLogin}>
                    <Box className={styles.formInput}>
                        <TextField
                            fullWidth
                            placeholder="Username"
                            id="fullWidth"
                            value={inputData.username}
                            onChange={(e) =>
                                setInputData({ ...inputData, username: e.target.value })
                            }
                            sx={{ fontFamily: "Nunito Sans", fontWeight: "400", mb: 2 }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PersonOutlineOutlined />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>

                    <Box className={styles.formInput}>
                        <TextField
                            fullWidth
                            placeholder="Password"
                            id="fullWidth"
                            type={inputData.showPassword ? "text" : "password"}
                            value={inputData.password}
                            onChange={(e) =>
                                setInputData({ ...inputData, password: e.target.value })
                            }
                            sx={{ fontFamily: "Nunito Sans", fontWeight: "400", mb: 2 }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <HttpsOutlined />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            edge="end"
                                        >
                                            {inputData.showPassword ? (
                                                <VisibilityOff />
                                            ) : (
                                                <Visibility />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>

                    {error.isError && (
                        <Box>
                            <Typography
                                variant="body2"
                                color="error"
                                sx={{
                                    textAlign: "center",
                                    fontSize: "14px",
                                    fontFamily: "Nunito Sans",
                                    fontWeight: "700",
                                    mb: 2,
                                }}
                            >
                                {error.errMessage}
                            </Typography>
                        </Box>
                    )}

                    <Box className={styles.formInput}>
                        <Button
                            sx={{
                                backgroundColor: "#1334B3",
                                color: "white",
                                textTransform: "none",
                                fontweight: "700",
                                fontSize: "16px",
                                fontFamily: "Nunito Sans",
                            }}
                            variant="contained"
                            fullWidth
                            type="submit"
                        >
                            Login
                        </Button>
                        <Typography
                            variant="body2"
                            sx={{
                                textAlign: "center",
                                color: "#686687",
                                fontSize: "14px",
                                cursor: "pointer",
                                my: 1.5,
                                fontFamily: "Nunito Sans",
                                fontWeight: "700",
                            }}
                            onClick={toggleModal}
                        >
                            Forgot Password?
                        </Typography>
                    </Box>
                </form>
            </div>

            <div className={styles.formFooter}>
                <Typography
                    variant="body2"
                    sx={{
                        textAlign: "center",
                        color: "#233244",
                        fontSize: "14px",
                        cursor: "pointer",
                        my: 1.5,
                        fontFamily: "Nunito Sans",
                        fontWeight: "400",
                    }}
                >
                    <span> Terms of Use </span> <span> Privacy Policy </span> <br />
                    <small> © Punctualiti 2022. All rights reserved </small>
                </Typography>
            </div>

            <div className={styles.modalContainer}>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={toggleModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <Box className={styles.modal}>
                            <Typography
                                sx={{ mb: 1, px: 1, py: 2, textAlign: "center" }}
                                id="transition-modal-title"
                                variant="h6"
                                component="h2"
                            >
                                Forgot Password
                            </Typography>

                            <Box className={styles.formInput}>
                                <TextField
                                    fullWidth
                                    placeholder="Username"
                                    id="fullWidth"
                                    value={inputData.username}
                                    onChange={(e) =>
                                        setInputData({ ...inputData, username: e.target.value })
                                    }
                                    sx={{ fontFamily: "Nunito Sans", fontWeight: "400", mb: 3 }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PersonOutlineOutlined />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>
                            <Box
                                className={styles.formInput}
                                sx={{
                                    textAlign: "center",
                                }}
                            >
                                <Button
                                    sx={{
                                        textTransform: "none",
                                        fontweight: "700",
                                        fontSize: "16px",
                                        fontFamily: "Nunito Sans",
                                        margin: "auto",
                                        cursor: "pointer",
                                    }}
                                    color="success"
                                    variant="contained"
                                    type="button"
                                    onClick={() => {
                                        toggleModal();
                                    }}
                                >
                                    Send Reset Link
                                </Button>
                            </Box>
                        </Box>
                    </Fade>
                </Modal>
            </div>
        </>
    );
};

export default Login;

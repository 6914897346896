import './App.css';
import Dashboard from "./App/Containers/Dashboard/Dashboard";
import Login from 'App/Containers/Login/Login';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={<Dashboard />}
        />
        <Route
          path="/dashboard"
          element={<Dashboard />}
        />
        <Route
          path="/dashboard/add-client"
          element={<Dashboard />}
        />
        <Route
          path="/login"
          element={<Login />}
        />
        <Route
          path="*"
          element={<Dashboard />}
        />
      </Routes>
    </Router>
  )
}

export default App
